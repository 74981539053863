<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order Items') }}
      </h2>
      <span>
        <feather-icon
          icon="LWarningIconBlue"
          class="featherIcon"
          size="30"
        />
        {{ $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>
      <!--      <h2 class="font-weight-bolder font-medium-5 pt-2">-->
      <!--        {{ $t('Cameras + Lenses -test') }}-->
      <!--      </h2>-->
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :fetched-data="serviceOrder"
        :is-searchable.camel="false"
      >
        <template #head(action)>
          <div
            class="d-flex p-0"
          >
            <b-form-checkbox class="_custom_checkbox" />
          </div>
        </template>
        <template #cell(action)>
          <div
            class="d-flex pl-1"
          >
            <b-form-checkbox
              v-model="picket"
              class="_custom_checkbox"
            />
          </div>
        </template>
        <template #cell(state)>
          <div
            class="d-flex pl-1"
          >
            <feather-icon
              icon="LUpPickIcon"
              class="featherIcon"
              size="30"
            />
          </div>
        </template>
      </l-table-list-collector>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'ServiceOrderItems',
  components: { BFormCheckbox, LTableListCollector },
  computed: {
    picket: {
      get(e) {
        console.log(e)
      },

      set(e) {
        console.log(e)
        // return true;
      },
    },
    serviceOrder() {
      return [
        // {
        //   state: 1,
        //   id: 2,
        //   sku: 'FF0001',
        //   name: 'FUJIFILM X-H2S Mirrorless',
        //   location: 'Damaged Items',
        //   asset_id: '2378623',
        // },
      ]
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
