<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <b-button
      class="cancelBtn font-medium-1 font-weight-bolder"
      variant="outline-primary"
      @click="$router.push({ name: 'home-warehouse-view' })"
    >
      {{ $t('Back to List') }}
    </b-button>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        disabled
      >
        <Printer>
          <div
            id="rfq"
            hidden
          >
            <RFQPrintTemplate />
          </div>
        </Printer>
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t('Print Pick List') }}</span>
      </b-button>
      <b-button
        variant="success"
        class="d-flex  justify-content-center ml-2"
        style="width: 170px; height: 40px;"
        disabled
      >
        <feather-icon
          icon="LPickIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t('Pick') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script setup>

import RFQPrintTemplate
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/components/RFQPrintTemplate.vue'
import { BButton } from 'bootstrap-vue'
import Printer from '@/views/components/Printer/Printer.vue'

export default {
  name: 'ServiceOrderFormBottom',
  components: { BButton, RFQPrintTemplate, Printer },
  setup() { },
}
</script>

<style scoped lang="scss">

</style>
