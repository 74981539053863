<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Skeleton',
}
</script>

<style lang="scss" scoped>

</style>
