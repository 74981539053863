<template>
  <div>
    <quote-status-bar
      :item="{}"
      :quote-status-bar="[]"
      class="ml-1"
    />
    <b-row class="mt-1">
      <b-col cols="6">
        <service-order-dispatch />
      </b-col>
      <b-col cols="6">
        <service-order-return />
      </b-col>
    </b-row>
    <service-order-information class="mt-1" />
    <service-order-items class="mt-1" />
    <service-order-form-bottom />

  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import ServiceOrderInformation from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderInformation.vue'
import ServiceOrderFormBottom from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderFormBottom.vue'
import ServiceOrderItems from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderItems.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'ServiceOrderForm',
  components: {
    BCol,
    BRow,
    ServiceOrderDispatch,
    QuoteStatusBar,
    ServiceOrderReturn,
    ServiceOrderInformation,
    ServiceOrderFormBottom,
    ServiceOrderItems,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    // transferOrder() {
    //   return this.$store.state[this.MODULE_NAME].transferOrder
    // },
  },
  setup() {
    // const { transferFields, MODULE_NAME, quoteStatusBar } = config()
    // return {
    //   transferFields,
    //   MODULE_NAME,
    //   quoteStatusBar,
    // }
  },
}
</script>

<style lang="scss" scoped>

</style>
