<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order RO-') }} {{ id }}
      </h3>
    </portal>
    <!--    <skeleton v-if="loading" />-->
    <service-order-form
      ref="service-orders-form"
    />
  </div>
</template>

<script>
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import ServiceOrderForm from './components/ServiceOrderForm.vue'

export default {
  name: 'RentalSales',
  components: { ServiceOrderForm },
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
    }
  },
  computed: {},
  created() {
  },
  methods: {},
  setup() {
    // const { MODULE_NAME, MODULE_NAME_CLONE } = config()
    // if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, rentalSalesModule)
    // onUnmounted(() => {
    //   if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    // })
    return {
      // MODULE_NAME,
      // MODULE_NAME_CLONE,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
